// Import our CSS
import "@/css/trimtex.css";

// These items should be loaded immediately to avoid layout shifting (CLS)
import 'lazysizes'
import Header from './components/ui/Header/Header'

Header()


export type ComponentConfig = {
    promise: Promise<object>,
    callback?: CallableFunction
}

const init = async function () {
    const promises: ComponentConfig[] = [];

    if (document.querySelector('[x-data]')) {
        promises.push({promise: import('./components/ui/Alpine/Alpine')})
    }

    if (document.querySelector('[data-plyr-video]')) {
        promises.push({promise: import ('./components/ui/Videos/Videos')})
    }

    if (document.querySelector('#mobile-menu-nav')) {
        promises.push({
            promise: import ('./components/ui/MobileMenu/MobileMenu')
        })
    }

    if (document.querySelector('[data-product-detail-slider]')) {
        promises.push({
            promise: import ('./components/ui/sliders/ProductDetailSlider')
        })
    }

    if (document.querySelector('[data-id^="cb-slider-"]')) {
        promises.push({
            promise: import ('./components/ui/sliders/ContentBuilderSlider')
        })
    }

    if (document.querySelector('[data-testimonials-slider]')) {
        promises.push({
            promise: import ('./components/ui/sliders/Testimonial')
        })
    }

    if (document.querySelector('.glightbox,[data-glightbox]')) {
        promises.push({
            promise: import ('./components/ui/Lightbox/Lightbox')
        })
    }
    promises.forEach(function (config: ComponentConfig) {
        config.promise.then(c => {
            let result = c.default;
            if (typeof c.default === 'function') {
                console.log('Calling c.default()')
                c.default()
            }
            if (config.callback) {
                console.debug('Invoking callback')
                config.callback(result)
            }
        });
    });
    return Promise.all(promises.map(p => p.promise));
}

init().then(() => {
    console.log('Done!')
})
